<template>
  <div  class="start-view">
    <!-- <p id="loadingMessage">⌛ ビデオをロードしています...</p> -->
    <canvas id="canvas" v-show="!loading" ref="canvas"></canvas>
    <!-- <div  class="start-button" @click="this.$router.push({ name: 'camera2' })">更新</div> -->
    <!-- <div id="output" v-show="!loading"> -->
      <!-- <p id="outputMessage" v-show="!code">QRコードが検出されませんでした。</p> -->
      <!-- <p v-if="code" ref="outputData">検出されたQRコードデータ: {{ codeData }}</p> -->
    <!-- </div> -->
  </div>
</template>

<script>
import jsQR from 'jsqr';
export default {
  name: "CameraView2",
  data() {
    return {
      loading: true,
      codeData: null,
      code: false
    }
  },
  mounted() {
    this.initCamera();
  },
  methods: {
    drawLine(begin, end, color) {
      const canvas = this.$refs.canvas.getContext("2d");
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    },
    initCamera() {
      const video = document.createElement("video");
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
        .then((stream) => {
          video.srcObject = stream;
          video.setAttribute("playsinline", true); // iOS safariではfullscreenにならないようにする
          video.play();
          this.tick(video);
        })
        .catch((error) => {
          console.error("メディアデバイスの取得に失敗しました:", error);
        });
    },
    tick(video) {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        this.loading = false;
        let canvasElement = this.$refs.canvas;
        let canvas = canvasElement.getContext("2d");
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        let code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" });
        if (code) {
          // this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
          // this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
          // this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
          // this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
          // this.codeData = code.data;
          this.$router.push({ name: 'result', params: { decodedContent: code.data } });
          this.code = true;
          
          return 
          
        } else {
          this.code = false;
        }
      }
      requestAnimationFrame(() => this.tick(video));
    }
  }
}
</script>

<style scoped>
#canvas {
  width: 100%;
  
}

video{
  width: 100%;

}
</style>